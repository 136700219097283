<template>
  <b-card
    overlay
    :img-src="img_src"
    :img-alt="getSafe(() => device.object)"
    text-variant="white"
    :title="getSafe(() => device.object)"
    :sub-title="lastTimeMessage"
    ref="camera-display"
  >
    <div style="background-color=#000 !important;">
      <b-card-text>
        <div class="text-center">
          <b-spinner
            variant="light"
            label="Loading..."
            style="invert(1);"
            v-if="lastTimeLoaded == -1"
          ></b-spinner>
        </div>
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "suitch-image-widget",
  components: {},
  props: {
    device: null,
  },
  mounted() {
    this.unsubscribe=this.$store.subscribe((mutation, state) => {
      if(mutation.type=="setMessage"){
        // verify if the type of message is for this widget
        if(this.device!= null 
        && state.realtime.last_message.type == "stream" 
        && state.realtime.last_message.token == this.device.token){
          this.img_src =
            "https://suitch.network/recordings/" +
            this.device.token +
            "/" +
            state.realtime.last_message.image;
          const this_time = new Date();
          this.lastTimeLoaded = this_time.getTime();
        }
      }
    });
    setInterval(() => {
      if (this.lastTimeLoaded == -1) {
        this.lastTimeMessage = "loading ...";
      } else {
        const this_time = new Date();
        const elapsed_seconds = Math.ceil(
          (this_time - this.lastTimeLoaded) / 1000
        );
        if (elapsed_seconds < 60) {
          this.lastTimeMessage = String(elapsed_seconds) + " seconds ago";
        } else {
          this.lastTimeMessage =
            String(Math.floor(elapsed_seconds / 60)) + " minutes ago";
        }
        if (elapsed_seconds > 10) {
          this.lastTimeLoaded = -1;
        }
      }
    }, 1000);
  },
  beforeDestroy() {
    // Unsubscribe from the store when the component is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();console.log("kill_subscribe");
    }
  },
  data() {
    return {
      unsubscribe: null,
      current_device: { connection_type: -1 },
      img_src: "media/bg/camera-blank.jpg",
      lastTimeLoaded: -1,
      lastTimeMessage: "loading ...",
    };
  },
  methods: {
    getSafe,
  },
  watch: {
    device: function(oldval, newval) {
      oldval, newval;
    },
  },
};
function getSafe(fn) {
    try { return fn(); }
    catch (e) {
      // happy
    }
}
</script>
